<template>
  <div ref="section" class="container my-14 lg:my-20">
    <div ref="title" v-if="data.title" class="mb-8 text-lg lg:mb-10 lg:text-xl">
      {{ data.title }}
    </div>
    <div class="grid-partners mx-auto grid w-full gap-y-8" :class="gridColsClass">
      <div
        v-for="partner in data.partners"
        :key="partner.name"
        :ref="setItemRef"
        class="partner flex flex-col justify-center px-3 lg:px-2"
      >
        <Image
          v-if="partner.logo"
          :responsive="false"
          class="mx-auto h-fit max-h-[120px] w-fit max-w-[150px]"
          sizes="(min-width: 400px) 150px, calc(50vw - 40px)"
          :src="partner.logo.responsiveImage ? partner.logo.responsiveImage?.src : partner.logo.url"
          :srcset="partner.logo.responsiveImage ? partner.logo.responsiveImage?.webpSrcSet : undefined"
          :alt="partner.logo.alt ? partner.logo.alt : ''"
          :width="200"
          :height="120"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { gsap } from 'gsap'

const props = defineProps({
  data: {
    type: Object,
    default: () => {}
  }
})

let tl: gsap.core.Timeline | null = null
let itemRefs = [] as any

const setItemRef = (el: VNodeRef | undefined) => {
  if (el) {
    itemRefs.push(el)
  }
}

const gridColsClass = computed(() => {
  if (props.data.partners.length === 4) {
    return 'grid-cols-4'
  } else if (props.data.partners.length === 5) {
    return 'grid-cols-5'
  } else if (props.data.partners.length === 6) {
    return 'grid-cols-6'
  } else if (props.data.partners.length === 7) {
    return 'grid-cols-7'
  } else if (props.data.partners.length === 8) {
    return 'grid-cols-8'
  } else if (props.data.partners.length === 9) {
    return 'grid-cols-9'
  } else if (props.data.partners.length === 10) {
    return 'grid-cols-10'
  } else {
    return 'grid-cols-6'
  }
})

const section = ref<HTMLElement | null>(null)
const title = ref<HTMLElement | null>(null)

const { stop } = useIntersectionObserver(
  section,
  ([{ isIntersecting }], _observerElement) => {
    if (isIntersecting) {
      if (tl) {
        tl.play()
      }
    }
  },
  {
    threshold: 0.2
  }
)

onBeforeUpdate(() => {
  itemRefs = []
})
onUpdated(() => {
  console.log(itemRefs)
})

onMounted(() => {
  nextTick(() => {
    if (title.value) {
      tl = gsap
        .timeline({
          paused: true
        })
        .from(
          title.value,
          {
            y: '100%',
            duration: 0.5,
            ease: 'power3.out'
          },
          'start'
        )
        .from(
          itemRefs,
          {
            stagger: {
              each: 0.15,
              from: 'random'
            },
            scale: 0,
            duration: 0.7,
            ease: 'back(1.1).out'
          },
          'start'
        )
        .addLabel('start')
    }
  })
})

onUnmounted(() => {
  stop()
})
</script>

<style lang="postcss">
.grid-partners {
  @media (max-width: 1023px) {
    @apply grid-cols-3;
  }

  @media (max-width: 639px) {
    @apply grid-cols-2;
  }
}
</style>
